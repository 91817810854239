<template>
	<div class="loginAll" :style="{'background-image': 'url('+imgUrl+')'}">
		<div class="mainLog m-width">
			<div class="leftBox">
				<!--<el-image :src="imgPath" fit="cover"></el-image>-->
				<img :src="imgPath" />
			</div>
			<div class="rightBox">
				<!-- 登录 两种方式 login-->
				<div class="login">
					<div class="login_title">
						免费注册
					</div>
					<!-- 内容部分 -->
					<div class="login_content">
						<el-form 
						:model="ruleForm" 
						status-icon 
						:rules="rules" 
						ref="ruleForm" 
						class="demo-ruleForm">
							<el-form-item  prop="phone" >
								<el-input type="tel" 
								v-model="ruleForm.phone" 
								oninput="value=value.replace(/[^\d]/g,'')"
								autocomplete="off"  placeholder="请输入您的11位手机号" maxlength="11"></el-input>
							</el-form-item>
							<el-form-item  prop="pass">
								<el-input type="password" v-model="ruleForm.pass" autocomplete="off" placeholder="请设置8-12位字母+数字密码" minlength="8" maxlength="12"></el-input>
							</el-form-item>
							<el-form-item  prop="confirmpassword">
								<el-input type="password" v-model="ruleForm.confirmpassword" autocomplete="off" placeholder="请确认8-12位字母+数字密码" minlength="8" maxlength="12"></el-input>
							</el-form-item>
							<el-form-item prop="code" >
								<div  class="d-flex">
									<el-input class="d-flex-l" v-model="ruleForm.code"  autocomplete="off" placeholder="请输入图形验证码" maxlength="4"></el-input>
									<img class="codeImg" :src="vsCode" alt="" @click="changeCodeImg()">
								</div>
							</el-form-item>
							<el-form-item  prop="mescode">
								<div class="d-flex">
									<div class="d-flex-l">
										<el-input v-model="ruleForm.mescode" oninput="value=value.replace(/[^\d]/g,'')"
										autocomplete="off"  placeholder="请输入短信验证码" maxlength="6"></el-input>
									</div>
									<el-button plain class="getCode" :disabled='!BtnStatus' @click="sendCode">{{BtnStatus?'获取验证码':`${countDownTime}秒后获取`}}</el-button>
																	
								</div>
								
							</el-form-item>
							<el-form-item  prop="readandagree">
								<div  class="real_mess">
									<el-checkbox-group v-model="ruleForm.readandagree">
										<el-checkbox name="type"></el-checkbox>
									</el-checkbox-group>
									<div class="rm_content">
										<span>我已阅读并同意 </span>
										<a href="javasctipt:;">《注册服务协议》</a>
										<a href="javasctipt:;">《隐私政策》</a>
									</div>
								</div>
							</el-form-item>
							<el-form-item class="lc_subBtn">
								<el-button type="primary" @click="submitForm('ruleForm')">免费注册</el-button>
							</el-form-item>
						</el-form>
					</div>
					<div class="hotline"> 
						<span>已有账号?</span>
						<a href="javascript:;" @click="$router.push({name:'Login'})">立即登录</a>
					</div>
				</div>	
			</div>
		</div>
	</div>
</template>

<script>
import {getCodeImg,sendCodeReg,register} from "@/assets/js/api";
export default {
	data() {
		var validatePhone= (rule, value, callback) => {
		    if (!value) {
		        return callback(new Error('手机号不能为空'))
		    }
		    if (!/^1[345789]\d{9}$/.test(value)) {
		        callback(new Error('请正确填写手机号'))
		    } else {
		        callback()
		    }
		}
		var validatePass =(rule, value, callback) => {
		  var passwordReg= '/^(?![0-9]+$)(?![a-z]+$)(?![A-Z]+$)(?!([^(0-9a-zA-Z)]|[])+$)([^(0-9a-zA-Z)]|[]|[a-z]|[A-Z]|[0-9]){6,}$/';
		  if (value === '') {
		    callback(new Error('请输入密码'));
		  }else if(!/(?=.*[a-zA-Z])(?=.*[0-9])[a-zA-Z0-9]{8,12}/.test(value)){
			callback(new Error('请输入8-12位字母+数字密码'));
		  }else {
		    if (this.ruleForm.confirmpassword !== '') {
		      this.$refs.ruleForm.validateField('confirmpassword');
		    }
		    callback();
		  }
		};
		var validatePass2 =(rule, value, callback) => {
		  if (value === '') {
		    callback(new Error('请再次输入密码'));
		  } else if (value !== this.ruleForm.pass) {
		    callback(new Error('两次输入密码不一致!'));
		  } else {
		    callback();
		  }
		};
		return {
		  ruleForm: {
			phone:'',//电话
			pass: '', //密码
			confirmpassword: '',//确认密码
			code:'', //验证码
			mescode:'', //短信验证码
			readandagree:[], //协议
			imgCode:'',//图片
		  },
		  rules: {
			phone:[{validator:validatePhone, trigger: 'blur' }],
			pass:[{ validator:validatePass, trigger: 'blur' }],
			code:[{required: true,trigger: 'blur',message: '图形验证码不能为空'}],
			confirmpassword:[{ validator:validatePass2, trigger: 'blur' }],
			mescode:[{required: true,trigger: 'blur',message: '短信验证码不能为空'}],
			readandagree:[{type:'array',required: true,trigger: 'change',message: '！您还未勾选同意项'}]
		  },
		  BtnStatus: true,// 是否禁止点击发送验证码按钮
		  countDownTime: 59,//验证码倒计时
		  vsCode:'' ,//图片验证码地址
		  imgPath: this.imgBasUrl2+'rz_login_bg.png',
		  imgUrl: this.imgBasUrl2+'bg3.png',
		}
	},
	mounted(){
		  this.changeCodeImg(); //显示验证码图片 
	},
    methods:{
		//点击验证码图片  刷新验证码
		changeCodeImg(){
			var _this = this;
			getCodeImg().then((res)=>{
				console.log(res)
				if(res.code === '1'){
					_this.vsCode = 'data:image/png;base64,'+ res.data.img;
					_this.ruleForm.imgCode = res.data.imgCode;//	获取图片返回的imgCode
				}else{
					this.$message.error(res.msg)
				}
				
			})
			
		},
    	//发送短信验证码
    	sendCode () {
			if(this.ruleForm.code==''){this.$message.error('请输入图形验证码'); return}
    		var _this = this;
    		//调接口
    		sendCodeReg({
    			phone  : this.ruleForm.phone, //手机号
    			scene  :'PC_REGISTER' ,//场景  默认值
				code   : this.ruleForm.code, //图片验证码
				imgCode: this.ruleForm.imgCode,//	获取图片返回的imgCode
    		}).then((res)=>{
    			if(res.code === '1'){
					_this.CodeTimer();
					_this.$message.success('短信验证码发送成功');
    			}else if(res.code === "-3"){
					_this.$message.error(res.msg);
					setTimeout(()=>{
						_this.changeCodeImg();
					},100)
				}else{
					_this.$message.error(res.msg)
				}
    		},(error)=>{
    			console.log(error)
    		})
    	},
    	//注册
    	submitForm(formName) {
    		var _this = this;
    		this.$refs[formName].validate((valid) => {
    			if (valid) {
    				register({
						code	       : this.ruleForm.imgCode,//图片验证码	
						confirmpassword: this.ruleForm.confirmpassword, //确认密码	
						imgCode	       : this.ruleForm.imgCode, //获取图片验证码返回的imgCode	
						password       : this.ruleForm.pass,//	密码
						phone	       : this.ruleForm.phone, //手机号	
						readandagree   : this.ruleForm.readandagree.length,//协议勾选0-未勾选1-已勾选
						scene	       :'PC_REGISTER',
						smsCode	       : this.ruleForm.mescode,  // 短信验证码
						// type	:,//用户身份 1：融资方 2：配资方
    				 }).then((res)=>{
    					if(res.code == '1'){
    						//注册成功后，跳到实名认证页
    						_this.$store.state.isLogin = true;
    						_this.$cookie.set('Rtoken',res.data.token);
    						_this.$cookie.set('RuserInfo', JSON.stringify(res.data.user));
    						_this.$router.push({name:'Index'});
    					}else{
    						_this.$message.error(res.msg)
    					}
    				},(err)=>{
    					console.log(err)
    				})
    			} else {
    				return false;
    			}
    		});
    	},
    	resetForm(formName) {
    		this.$refs[formName].resetFields();
    	},
    	//倒计时
    	CodeTimer(){
    		var _this = this;
    		if (this.BtnStatus) {
    			this.BtnStatus = false;
    			let timer = setInterval(() => {
    				_this.countDownTime--;
    				if (_this.countDownTime <1) {
    					clearInterval(timer);
    					localStorage.removeItem('myEndTime');
    					_this.countDownTime = 59 ;
    					_this.BtnStatus = true;
    				} else {
						_this.CodeTimer();
					}
    			}, 1000)
    		}
    	},
    },
};

</script>

<style lang="scss" scoped>
.loginAll{
  width: 100%;
  height:100vh;
  background: center no-repeat;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
}

.mainLog{
  height: 560px;
  background: #FFFFFF;
  display: flex;
  align-items: center;
  overflow: hidden;
 
  .leftBox{
	  width:45%;
	  height: 100%;
	  img{
		width: 100%;
		height: 100%;
	  }
  }
  .rightBox{
	  padding:5% 7%;
	  width:55%;
	  height: 100%;
	  box-sizing: border-box;
	  overflow: hidden;
  	.login_title{
  	  text-align: center;
	  font-size: 20px;
	  font-weight: bold;
	  color: #2E3033;
  	} 
	/* 登录 start */
	.login_content{
	    padding: 28px 10px 0px;
		.real_mess {
		  line-height: 22px;
		  display: flex;
		  font-size: 12px;
		  .el-checkbox:last-of-type{
		    margin-right:6px;
		  }
		}
		
		.el-checkbox__inner{
		  border-color: #cccccc;
		}
		.lc_subBtn{
			margin-bottom: 0;
			.el-button{
				 width: 100%;
				 display: block;
			}
		}
	}
	
	.hotline{
	    color: #606366;
	    text-align: center;
		margin-top: 20px;
		a{
			color:#1677FF;
			margin-left: 10px;
		}
		
	}
	
	
	
	
  }
}
.lc_subBtn .el-button+.el-button{
	margin: 14px  0 ;
 }
.d-flex{
	display: flex ;
	overflow: hidden;
	.d-flex-l{
		flex: 1;
	}
	button{
		margin-left: 10px;
		width: 100px;
		padding: 12px 10px;
	}
	.codeImg{
		margin-left: 10px;
		width: 92px;
	}
	
}

@media screen and (max-width: 992px) {
  .m-width{
     width:80%;
  }
}
@media screen and (min-width: 992px) {
    .m-width{
   	   width: 60%;
    }
}
@media screen and (min-width: 1200px) {
   .m-width{
	   width:50%;
   }
}
@media screen and (min-width: 2000px) {
   .m-width{
	   width:1000px;
   }
}
</style>
