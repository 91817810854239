<template>
	<div class="loginAll" :style="{'background-image': 'url('+imgUrl+')'}">
		<div class="mainLog m-width">
				<div class="leftBox">
					<el-image :src="imgPath" fit="cover"></el-image>
				</div>
				<div class="rightBox">
					<div class="login">
						<div class="login_title">
						  重置密码
						</div>
						<!-- 内容部分 -->
						<div class="login_content">
							<el-form 
							:model="ruleForm" 
							status-icon 
							:rules="rules" 
							ref="ruleForm" 
							class="demo-ruleForm">
								<el-form-item  prop="phone" >
									<el-input type="tel" 
									v-model="ruleForm.phone" 
									oninput="value=value.replace(/[^\d]/g,'')"
									autocomplete="off"  placeholder="请输入您的11位手机号" maxlength="11"></el-input>
								</el-form-item>
								</el-form-item>
								<el-form-item  prop="mescode">
									<div class="d-flex">
										<el-input
										class="d-flex-l" 
										v-model="ruleForm.mescode" 
										oninput="value=value.replace(/[^\d]/g,'')"
										autocomplete="off"  placeholder="请输入短信验证码" maxlength="6"></el-input>
										<el-button plain class="getCode" :disabled='!BtnStatus' @click="sendCode">{{BtnStatus?'获取验证码':`${countDownTime}秒后获取`}}</el-button>
																		
									</div>
								</el-form-item>
								<el-form-item  prop="pass">
									<el-input type="password" v-model="ruleForm.pass" autocomplete="off" placeholder="请设置8-12位字母+数字密码" minlength="8" maxlength="12"></el-input>
								</el-form-item>
								<el-form-item  prop="checkPass">
									<el-input type="password" v-model="ruleForm.checkPass" autocomplete="off" placeholder="请确认8-12位字母+数字密码" minlength="8" maxlength="12"></el-input>
								</el-form-item>
								
								<el-form-item class="lc_subBtn">
									<el-button type="primary" @click="submitForm('ruleForm')">确定</el-button>
								</el-form-item>
							  
							</el-form>
						  </div>
						  
						  <div class="hotline"> 
							<span>没有账号?</span>
							<a href="javascript:;" @click="$router.push({name:'Register'})">立即注册</a>
						  </div>
						</div>	
				</div>
		</div>
		
	</div>
</template>

<script>
import {sendCodeForget,forgetPwd} from "@/assets/js/api";
export default {
	data() {
		var validatePhone= (rule, value, callback) => {
		    if (!value) {
		        return callback(new Error('手机号不能为空'))
		    }
		    if (!/^1[345789]\d{9}$/.test(value)) {
		        callback(new Error('请正确填写手机号'))
		    } else {
		        callback()
		    }
		}
		var validatePass =(rule, value, callback) => {
		  var passwordReg= '/^(?![0-9]+$)(?![a-z]+$)(?![A-Z]+$)(?!([^(0-9a-zA-Z)]|[])+$)([^(0-9a-zA-Z)]|[]|[a-z]|[A-Z]|[0-9]){6,}$/';
		  if (value === '') {
		    callback(new Error('请输入密码'));
		  }else if(!/(?=.*[a-zA-Z])(?=.*[0-9])[a-zA-Z0-9]{8,12}/.test(value)){
			callback(new Error('请输入8-12位字母+数字密码'));
		  }else {
		    if (this.ruleForm.checkPass !== '') {
		      this.$refs.ruleForm.validateField('checkPass');
		    }
		    callback();
		  }
		};
		var validatePass2 =(rule, value, callback) => {
		  if (value === '') {
		    callback(new Error('请再次输入密码'));
		  } else if (value !== this.ruleForm.pass) {
		    callback(new Error('两次输入密码不一致!'));
		  } else {
		    callback();
		  }
		};
		return {
		  ruleForm: {
			phone:'',//电话
			pass: '', //密码
			checkPass: '',//确认密码
			code:'', //验证码
			mescode:'', //短信验证码
		  },
		  rules: {
			phone:[{validator:validatePhone, trigger: 'blur' }],
			pass:[{ validator:validatePass, trigger: 'blur' }],
			checkPass:[{ validator:validatePass2, trigger: 'blur' }],
			mescode:[{required: true,trigger: 'blur',message: '短信验证码不能为空'}],
		  },
		  BtnStatus: true,// 是否禁止点击发送验证码按钮
		  countDownTime: 59,//验证码倒计时
		  imgPath: this.imgBasUrl2+'rz_login_bg.png',
		  imgUrl: this.imgBasUrl2+'bg3.png',
		}
	},
	
    methods:{
    	//发送短信验证码
    	sendCode () {
    		var _this = this;
    		//调接口
    		sendCodeForget({
    			mobile : this.ruleForm.phone, //手机号
    			scene  :'PC_FORGET_PWD' ,//场景  默认值
    		}).then((res)=>{
				console.log(res)
    			if(res.code === '1'){
    				_this.CodeTimer();
    			}else{
					_this.$message.error(res.msg)
				}
				console.log(res)
    		},(error)=>{
    			console.log(error)
    		})
    	},
    	//注册
    	submitForm(formName) {
    		//console.log(this.ruleForm.agreement.length);
    		var _this = this;
    		this.$refs[formName].validate((valid) => {
    			if (valid) {
    				forgetPwd({
						confirmPwd: this.ruleForm.checkPass, //确认密码
						pwd       : this.ruleForm.pass,//	密码
						mobile	  : this.ruleForm.phone, //手机号	
						scene	  :'PC_FORGET_PWD',
						smsCode	  : this.ruleForm.mescode,  // 短信验证码
						// type	:,//用户身份 1：融资方 2：配资方
    				 }).then((res)=>{
						 console.log(res)
    					if(res.code == '1'){
    						_this.$message.error('您已成功重置密码');
							_this.$router.push({name:'Login'})
    					}else{
    						_this.$message.error(res.msg)
    					}
    				},(err)=>{
    					console.log(err)
    				})
    			
    			
    			} else {
    				console.log('error submit!!');
    				return false;
    			}
    		});
    	},
    	resetForm(formName) {
    		this.$refs[formName].resetFields();
    	},
    	//倒计时
    	CodeTimer(){
    		var _this = this;
    		if (this.BtnStatus) {
    			this.BtnStatus = false;
    			let timer = setInterval(() => {
    				_this.countDownTime--;
    				if (_this.countDownTime <1) {
    					clearInterval(timer);
    					localStorage.removeItem('myEndTime');
    					_this.countDownTime = 59 ;
    					_this.BtnStatus = true;
    				} else {
						_this.CodeTimer();
					}
    			}, 1000)
    		}
    	},
		
    	  
    },
    mounted(){
   	
    },

 
};

</script>

<style lang="scss" scoped>
.loginAll{
  width: 100%;
  height:100vh;
  background:center no-repeat;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
}

.mainLog{
  // height: 560px;
  height: 450px;
  background: #FFFFFF;
  display: flex;
  align-items: center;
  overflow: hidden;
 
  .leftBox{
	  background-color: red;
	  width:45%;
	  height: 100%;
	  .el-image{
	  	width: 100%;
	  	height: 100%;
	  }
  }
  .rightBox{
	  padding:5% 7%;
	  width:55%;
	  height: 100%;
	  box-sizing: border-box;
	  overflow: hidden;
  	.login_title{
  	  text-align: center;
	  font-size: 20px;
	  font-weight: bold;
	  color: #2E3033;
  	} 
	/* 登录 start */
	.login_content{
	    padding: 28px 10px 0px;
		.real_mess {
		  line-height: 22px;
		  display: flex;
		  font-size: 12px;
		  .el-checkbox:last-of-type{
		    margin-right:6px;
		  }
		}
		
		.el-checkbox__inner{
		  border-color: #cccccc;
		}
		.lc_subBtn{
			margin-bottom: 0;
			.el-button{
				 width: 100%;
				 display: block;
			}
		}
	}
	
	.hotline{
	    color: #606366;
	    text-align: center;
		margin-top: 30px;
		a{
			color:#1677FF;
			margin-left: 10px;
		}
		
	}
	
	
	
	
  }
}
.lc_subBtn .el-button+.el-button{
	margin: 14px  0 ;
 }
.d-flex{
	display: flex ;
	overflow: hidden;
	.d-flex-l{
		flex: 1;
	}
	button{
		margin-left: 10px;
		width: 100px;
		padding: 12px 10px;
		
	}
	.codeImg{
		margin-left: 10px;
		width: 92px;
	}
	
}

@media screen and (max-width: 992px) {
  .m-width{
     width:80%;
  }
}
@media screen and (min-width: 992px) {
    .m-width{
   	   width: 60%;
    }
}
@media screen and (min-width: 1200px) {
   .m-width{
	   width:50%;
   }
}
@media screen and (min-width: 2000px) {
   .m-width{
	   width:1000px;
   }
}
</style>
