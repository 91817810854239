<template>
	<div class="loginAll" :style="{'background-image': 'url('+imgUrl+')'}">
		<div class="mainLog m-width">
			<div class="leftBox">
				<el-image :src="imgPath" fit="cover"></el-image>
			</div>
			</el-col>
			<div class="rightBox">
				<!-- 登录 两种方式 login-->
				<div class="login">
					<div class="login_header">
					  <p :class="{lb_on:loginWay}"><a href="javascript:;" @click="loginWay=true;$refs['pwdForm'].resetFields();">密码登录</a></p>
					  <p :class="{lb_on: !loginWay}"><a href="javascript:;"  @click="loginWay=false;$refs['messForm'].resetFields();">短信登录</a></p>
					</div>
					<!-- 内容部分 -->
					<div class="login_content">
					  <div :class="{on:loginWay}" class="lc_form">
						<el-form 
						:model="pwdForm" 
						status-icon 
						:rules="rules" 
						ref="pwdForm" 
						class="demo-ruleForm">
							<el-form-item prop="phone" class="login-con">
							  <el-input type="text"
							  v-model="pwdForm.phone"
							  oninput="value=value.replace(/[^\d]/g,'')"
							  placeholder="请输入手机号" maxlength="11"></el-input>
							</el-form-item>
							<el-form-item prop="pass" class="login-con mag-bn">
							  <el-input 
							  v-model="pwdForm.pass"  
							  @keyup.enter.native="submitForm('pwdForm')"
							  type="password" placeholder="请输入密码" autocomplete="off" minlength="8"  maxlength="12"></el-input>
							</el-form-item>
							<el-form-item class="login_forget">
							  <el-button type="text" @click="$router.push({name:'ForgotPwd'})">忘记密码？</el-button>
							</el-form-item>
						  <el-form-item class="lc_subBtn">
							<el-button type="primary" @click="submitForm('pwdForm')">登录</el-button>
							<!-- <el-button plain >立即注册</el-button> -->
						  </el-form-item>
						  
						</el-form>
					  </div>
					  <div :class="{on:!loginWay}" class="lc_form">
						<el-form 
						  :model="messForm" 
						  status-icon 
						  :rules="rules" 
						  ref="messForm" 
						  class="demo-ruleForm mes-login">
							<el-form-item prop="phone" class="login-con">
							  <el-input type="text" 
							  v-model="messForm.phone"  
							  oninput="value=value.replace(/[^\d]/g,'')"
							  placeholder="请输入手机号" maxlength="11"></el-input>
							</el-form-item>
							<el-form-item prop="mescode" class="mag-bn" >
							  <div class="mes-code">
								<el-input 
								@keyup.enter.native="submitForm('messForm')"
								v-model="messForm.mescode"
								type="text" autocomplete="off" placeholder="请输入短信验证码"
								oninput="value=value.replace(/[^\d]/g,'')"
								maxlength="6" class="flex1"></el-input>
								<el-button type="primary" class="getCode" :disabled='!BtnStatus' 
								@click="sendCode">{{BtnStatus?'获取验证码':`${countDownTime}秒后获取`}}</el-button>
							  </div>
							</el-form-item>
							<el-form-item class="login_forget">
							  <el-button type="text" @click="$router.push({name:'ForgotPwd'})">忘记密码？</el-button>
							</el-form-item>
						   
							<el-form-item class="lc_subBtn">
							  <el-button type="primary" @click="submitForm('messForm')" >登录</el-button>
							</el-form-item>
						  </el-form>
						</div>
					  <div class="hotline"> 
						<span>没有账号?</span>
						<a href="javascript:;" @click="$router.push({name:'Register'})">立即注册</a>
					  </div>
					</div>
							  
				</div>
			</div>
				
			
		</div>
		
	</div>
</template>

<script>
import {getCodeImg,sendCodeLog,pwdLogin,mesLogin} from "@/assets/js/api";
import { mapMutations } from 'vuex';
export default {
  data() {
    var validatePhone= (rule, value, callback) => {
      if (!value) {
        return callback(new Error('手机号不能为空'))
      }
      if (!/^1[345789]\d{9}$/.test(value)) {
        callback(new Error('请正确填写手机号'))
      } else {
        callback()
      }
    }
    var validatePass =(rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入密码'));
      }else if(!/(?=.*[a-zA-Z])(?=.*[0-9])[a-zA-Z0-9]{8,12}/.test(value)){
		    callback(new Error('请输入8-12位字母+数字密码'));
	    }else {
        callback();
      }
    };
    var validatePassing =(rule, value, callback) => {
      if (value === false) {
        callback(new Error('请先进行安全校验'));
      }else {
        callback();
      }
    };
    return {
      lh_select:0,
      loginWay:true,//true代表密码登陆, false代表短信
      computeTime: 0,
      timer:null,
      centerDialogVisible: false,
      changeVisible:false,
      pwdForm: {
        phone:'',
        pass: '',
        isPassing:false
      },
      messForm: {
        phone:'',
        mescode:'',
        isPassing:false
      },
      rules:{
        phone:[{validator:validatePhone, trigger: 'blur' }],
        pass:[{ validator:validatePass, trigger: 'blur' }],
        mescode:[{required: true,trigger: 'blur',message: '短信验证码不能为空'}],
        // isPassing:[{ validator:validatePassing, trigger: 'blur' }],
      },
      BtnStatus: true,// 是否禁止点击发送验证码按钮
	  countDownTime: 59,//验证码倒计时
      loadingArea:false,//loading加载
	  imgPath: this.imgBasUrl2+'rz_login_bg.png',
	  imgUrl: this.imgBasUrl2+'bg3.png',
    }
  },
  methods:{
    reset() {
      this.isPassing = false;
      if(this.$refs.dragVerify != undefined){
        this.$refs.dragVerify.reset();
      }
    },
    passcallback2() {
    },
    //发送验证码
    sendCode () {
      var _this = this;
      let tel = this.messForm.phone;  
      if(!/^1[345789]\d{9}$/.test(tel)){
        this.$message.error('请输入正确手机号');
      }else{
        //调接口
        sendCodeLog({
          phone: tel, //手机号
          scene  :'PC_LOGIN' //场景  默认值
        }).then((res)=>{
          if(res.code === '1'){
            _this.CodeTimer();
			_this.$message.success('短信验证码发送成功');
          }else{
            _this.$message.error(res.msg)
          }
        },
        (err)=>{
          console.log(err)
        })
      }  
    },
    ...mapMutations(['changeLogin']),
    submitForm(formName) {
      var _this = this;
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.loadingArea = true;
		 
          if(formName ==='pwdForm'){ 
            //密码登录
			pwdLogin({
              phone   : _this.pwdForm.phone, //手机号
              password :_this.pwdForm.pass,
              loginType:1,	//登录类型(1PC登录，4资产管理)
            }).then((res)=>{
				  console.log(res)
				if(res.code === '1'){
				  //存储用户信息到cookie中
				  _this.$cookie.set('RuserInfo', JSON.stringify(res.data.user));
				  _this.$cookie.set('Rtoken', res.data.token)
				  _this.$store.state.infor = res.data.user;
				  _this.$store.state.isLogin = true;
				  _this.$router.push({name:'Index'});
				 _this.$message.success('登录成功');
				}else{
				  _this.$message.error(res.msg);
				}
				this.loadingArea = false;
			},(err)=>{
				console.log(err)
			})
         
          }else if(formName === 'messForm'){ 
            //短信登录
			mesLogin({
              phone: _this.messForm.phone, //手机号
              smsCode:_this.messForm.mescode,  // 	短信验证码
              scene: 'PC_LOGIN'// 场景
            })  .then((res)=>{
				  console.log(res)
				if(res.code === '1'){
				  //存储用户信息到cookie中
				  _this.$cookie.set('RuserInfo', JSON.stringify(res.data.user));
				  _this.$cookie.set('Rtoken', res.data.token)
				  _this.$store.state.infor = res.data.user;
				  _this.$store.state.isLogin = true;
				  _this.$router.push({name:'Index'});
				 _this.$message.success('登录成功');
				}else{
				  _this.$message.error(res.msg);
				}
				this.loadingArea = false;
			},(err)=>{
				console.log(err)
			})
          }
         
          
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
      this.centerDialogVisible = false;//重置密码弹框隐藏
    },
    //倒计时
    CodeTimer(){
      var _this = this;
      if (this.BtnStatus) {
        this.BtnStatus = false;
        let timer = setInterval(() => {
          _this.countDownTime--;
          if (_this.countDownTime <1) {
            clearInterval(timer);
            _this.countDownTime = 59 ;
            _this.BtnStatus = true;
          } else {
            _this.CodeTimer();
          }
        }, 1000)
      }
    },

  },
};

</script>

<style lang="scss" scoped>
.loginAll{
  width: 100%;
  height:100vh;
  background:center no-repeat;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
}

.mainLog{
  // height: 560px;
  height: 418px;
  background: #FFFFFF;
  display: flex;
  align-items: center;
  overflow: hidden;
  .leftBox{
	  background-color: red;
	  width:45%;
	  height: 100%;
	  .el-image{
		width: 100%;
		height: 100%;
	  }
  }
  .rightBox{
	  padding: 8%;
	  width:55%;
	  box-sizing: border-box;
  	.login_header{
  	  display: flex;
	  overflow: hidden;
	  
	  p{
	    flex: 1;
	    padding-bottom: 10px;
	    border-bottom: 1px solid #CCCCCC;
	  }
	  a{
	      font-size: 16px;
	      color: #666666;
	      display: block;
	      text-align: center;
	  }
	  .lb_on{
	      font-weight: 600;
	      border-color: #016FF1;
	  }
  	} 
	/* 登录 start */
	.login_content{
	    padding: 28px 10px 0px;
		.lc_form{
		  display: none;
		}
		.on{
		  display:block;
		}
	}
	 .el-input__inner{
	  border: 1px solid #999999;
	 }
	
	 .login_forget{
	  text-align: right;
	  margin-bottom:0 0 10px 0;
	 }
	 .login_forget {
		 button{
		  font-size: 12px;
		  letter-spacing: 1px;
		 	color: #999999;
		 }
	 }
	
	.change_tel{
	  text-align: center;
	}
	.lc_subBtn{
		margin-bottom: 0;
		.el-button{
		 width: 100%;
		 display: block;
		}
	}
	
	 
	.mag-bn{
		margin-bottom: 0;
	}
	.mes-login {
		.mes-code{
		  display: flex;
		  .flex1{
		  	flex: 1;
		  	margin-right: 10px;
		  }
		}
	}
	.lcf_code {
		.el-button{
			width: 90px;
			float: right;
			text-align: center;
		}
	}
	
	.wp-insan {
		.el-input__suffix{
		  right:-24px;
		}
	}
	
	
	.hotline{
	    color: #606366;
	    text-align: center;
		margin-top: 30px;
		a{
			color:#1677FF;
			margin-left: 10px;
		}
		
	}
	
	
	.login_ver{
	    display: flex;
	    justify-content: space-between;
		input{
		    width: 158px;
		}
	}
	 .get_verification{
	    background-color: #bbbbbb;
	    border-radius: 2px;
		border: solid 1px #cccccc;
	    width: 88px;
	    color: #ffffff;
	}
	
  }
}
.lc_subBtn .el-button+.el-button{
	margin: 14px  0 ;
 }


@media screen and (max-width: 992px) {
  .m-width{
     width:80%;
  }
}
@media screen and (min-width: 992px) {
    .m-width{
   	   width: 60%;
    }
}
@media screen and (min-width: 1200px) {
   .m-width{
	   width:50%;
   }
}
@media screen and (min-width: 2000px) {
   .m-width{
	   width:1000px;
   }
}
</style>
